import AP from "../../productosImg/Vip/AP.png";
import TEC9 from "../../productosImg/Vip/TEC9.png";
import GLOCK19 from "../../productosImg/Vip/GLOCK19.png";
export const ARMAS_VIP_LVL_1 = [
  {
    id: 1,
    img: AP,
    cartel: "LVL 1",
    titulo: "AP",
    cantidad: 1,
    precio: 25,
  },
  {
    id: 2,
    img: TEC9,
    cartel: "LVL 1",
    titulo: "TEC-9",
    cantidad: 1,
    precio: 25,
  },
  {
    id: 3,
    img: GLOCK19,
    cartel: "LVL 1",
    titulo: "GLOCK 19",
    cantidad: 1,
    precio: 25,
  },
];