import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./styles/premium.css";
import MpImg from "../img/tarjetas.webp";
import paypalImg from "../img/paypal.webp";
import { handleDireccionCorrecta, handleOptionChange } from "../../peticiones/funcionesFromFactura";
export default function FormComprar() {
  const { tipo, titulo, precio } = useParams();
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [direccion, setDireccion] = useState("");
  const [direccion2, setDireccion2] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [pais, setPais] = useState("");
  const [CodPostal, setCodPostal] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [monto, setMonto] = useState(5);
  const [selectedOption, setSelectedOption] = useState("opcion1");
  const [valuePrecio, setValuePrecio] = useState(parseFloat(precio));

  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMessage("");
      setSuccessMessage("");
    }, 2000);
    return () => clearTimeout(timer);
  }, [errorMessage, successMessage]);

  const handleValidateAddress = async (e) => {
    const data = {
      nombre,
      apellido,
      email,
      tipo,
      titulo,
      telefono,
      direccion,
      direccion2,
      ciudad,
      pais,
      precio,
      CodPostal,
      selectedOption,
    };
    e.preventDefault();
    setIsLoading(true);
    handleDireccionCorrecta(setIsLoading, data, setSuccessMessage, setErrorMessage);
  };
  // function redondearA999ConDecimal(numero) {
  //   return Math.round(numero / 1000) * 1000 - 1;
  // }

  return (
    <section className="container-form form-compra">
      <div className="container-login-register">
        <form className="form-login-register" onSubmit={handleValidateAddress}>
          {isLoading && (
            <div className="spinner-border spinner" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
          <h1>Detalles de facturación</h1>
          <div className="inputs form-compra">
            <h2>Información Personal</h2>
            <div className="div-input-form">
              <div className="input form-compra">
                <i className="fa-solid fa-user icon-input"></i>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  required
                />
              </div>
              <div className="input form-compra">
                <i className="fa-solid fa-user icon-input"></i>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Apellido"
                  value={apellido}
                  onChange={(e) => setApellido(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="div-input-form">
              <div className="input form-compra">
                <i className="fa-solid fa-user icon-input"></i>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input form-compra">
                <i className="fa-solid fa-user icon-input"></i>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Telefono"
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                  required
                />
              </div>
            </div>
            <h2>Dirección de Facturación</h2>
            <div className="input form-compra">
              <i className="fa-solid fa-user icon-input"></i>
              <input
                type="text"
                className="form-control"
                placeholder="Direccion 1"
                value={direccion}
                onChange={(e) => setDireccion(e.target.value)}
                required
              />
            </div>
            <div className="input form-compra">
              <i className="fa-solid fa-user icon-input"></i>
              <input
                type="text"
                className="form-control"
                placeholder="Direccion 2"
                value={direccion2}
                onChange={(e) => setDireccion2(e.target.value)}
              />
            </div>
            <div className="div-input-form">
              <div className="input form-compra">
                <i className="fa-solid fa-user icon-input"></i>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ciudad"
                  value={ciudad}
                  onChange={(e) => setCiudad(e.target.value)}
                  required
                />
              </div>
              <div className="input form-compra">
                <i className="fa-solid fa-user icon-input"></i>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pais"
                  value={pais}
                  onChange={(e) => setPais(e.target.value)}
                  required
                />
              </div>
              <div className="input form-compra">
                <i className="fa-solid fa-user icon-input"></i>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Codigo Postal"
                  value={CodPostal}
                  onChange={(e) => setCodPostal(e.target.value)}
                  required
                />
              </div>
            </div>
            <h2>Metodo de Pago</h2>
            <div className="selector">
              <label>
                <input
                  type="radio"
                  name="opcion"
                  value="opcion1"
                  required
                  checked={selectedOption === "opcion1"}
                  onChange={() => handleOptionChange("opcion1", setSelectedOption)}
                />
                <img src={MpImg} alt="" />
              </label>
              <label>
                <input
                  type="radio"
                  name="opcion"
                  value="opcion2"
                  required
                  checked={selectedOption === "opcion2"}
                  onChange={() => handleOptionChange("opcion2", setSelectedOption)}
                />
                <img src={paypalImg} alt="" />
              </label>
              {/* <label>
                <input
                  type="radio"
                  name="opcion"
                  value="opcion3"
                  checked={selectedOption === "opcion3"}
                  onChange={() => handleOptionChange("opcion3")}
                />
                Opción 3
              </label> */}
            </div>
          </div>
          {successMessage && <p className="success-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div className="btn-form">
            <button type="submit">Comprar</button>
          </div>
        </form>
      </div>
    </section>
  );
}
