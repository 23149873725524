import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { URL } from "../../config";
import "./styles/Contactar.css";
export default function Contactar({}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  let cant = 0;
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message) {
      Swal.fire({
        icon: "error",
        text: `Por favor Escriba un mensaje`,
      });
      return;
    }
    if (message.length <= 10) {
      Swal.fire({
        icon: "error",
        text: `Coloque mas de 10 Caracteres en el mensaje!`,
      });
      return;
    }
    const cantidadMessage = localStorage.getItem("sendMessage");
    if (cantidadMessage < 3) {
      const formData = {
        name: name,
        email: email,
        message: message,
      };
      try {
        const response = await axios.post(`${URL}/send-contact`, formData);
        if (response.status === 200) {
          cant = cant + 1;
          localStorage.setItem("sendMessage", cant);
          Swal.fire({
            title: `messageSuccess`,
            text: `¡Mensaje Enviado Correctamente!`,
            icon: "success",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: `Error al enviar el mensaje`,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          text: `Error al enviar el mensaje`,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: `Error al enviar el mensaje`,
      });
    }
  };
  return (
    <div className="container-form">
      <div className="container-login-register">
        <form id="form" className="form-login-register" onSubmit={handleSubmit}>
          <h1>CONTACTANOS</h1>
          <div className="input">
            <i className="fa-solid fa-lock icon-input"></i>
            <input id="name" type="text" required placeholder="Discord" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="input">
            <i className="fa-solid fa-lock icon-input"></i>
            <input
              id="email"
              type="email"
              required
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input">
            <i className="fa-solid fa-lock icon-input textarea"></i>
            <textarea
              id="message"
              type="text"
              placeholder="Mensaje"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="btn-form">
            <button type="submit">Enviar</button>
          </div>
        </form>
      </div>
    </div>
  );
}
