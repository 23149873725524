import React, { useRef, useState } from "react";
import { URL } from "../../../config.js";
import cl from "./roulette.module.scss";
import RouletteItem from "./RouletteItem";
import { Roulette } from "./roulette.classes.ts";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import caja_ruleta from "../productosImg/caja-ruleta.png";
import { guardarProductoWinner, restarRestarDinero } from "../../../peticiones/findUser.js";
import { useParams } from "react-router";
const McRoulette = ({ userLogin, CajaAll, weapons, weaponsCount, transitionDuration }) => {
  const [activeBox, setActiveBox] = useState(false);
  const [rouletteWeapons, setRouletteWeapons] = useState(weapons || []);
  const [weaponPrizeId, setWeaponPrizeId] = useState(-1);
  const [isReplay, setIsReplay] = useState(false);
  const [isSpin, setIsSpin] = useState(false);
  const [isSpinEnd, setIsSpinEnd] = useState(false);
  const [winHistory, setWinHistory] = useState([]);
  const [selectedWeapon, setSelectedWeapon] = useState(null);
  const { caja } = useParams();
  const rouletteContainerRef = useRef(null);
  const weaponsRef = useRef(null);

  function transitionEndHandler() {
    const selected = rouletteWeapons[weaponPrizeId];
    setWinHistory(winHistory.concat(selected));
    setSelectedWeapon(selected);
    setIsSpin(false);
    setIsSpinEnd(true);
  }

  function prepare() {
    weaponsRef.current.style.transition = "none";
    weaponsRef.current.style.left = "0px";
  }

  function load() {
    let winner = weapons[Math.floor(Math.random() * weapons.length)];
    const roulette = new Roulette({
      winner,
      weapons,
      rouletteContainerRef,
      weaponsRef,
      weaponsCount,
      transitionDuration,
    });

    roulette.set_weapons();
    setRouletteWeapons(roulette.weapons);
    guardarProductoWinner(CajaAll, winner, userLogin);
    return roulette;
  }
  function play() {
    if (userLogin && CajaAll && userLogin.dinero >= CajaAll.precio) {
      if (isReplay) {
        prepare();
      }
      if (!activeBox) {
        setActiveBox(true);
      }
      setIsSpin(true);
      const roulette = load();
      restarRestarDinero(userLogin, CajaAll.precio);
      setTimeout(() => {
        setIsSpin(true);
        setWeaponPrizeId(roulette.spin());
        setIsReplay(true);
      }, 1000);
    } else {
      Swal.fire({
        title: "¡Sin Saldo!",
        text: "No posee Saldo para tirar ¡Por favor Recarga!",
        icon: "error",
        confirmButtonText: `Ok`,
      });
    }
  }

  return (
    <div className="ruleta-cajas">
      {!activeBox ? (
        <div className="container-elements carrousel">
          <div className="img-ruleta">
            <img src={caja_ruleta} alt="caja de ruleta LazonaRolplay" />
          </div>
        </div>
      ) : (
        <div className={cl.rouletteWrapper}>
          <div ref={rouletteContainerRef}>
            <div className={cl.evRoulette}>
              <div className={cl.evTarget}></div>
              <div ref={weaponsRef} className={cl.evWeapons} onTransitionEnd={transitionEndHandler}>
                {rouletteWeapons.map((w, i) => (
                  <RouletteItem
                    key={i}
                    id={i}
                    isLoser={i !== weaponPrizeId && !isSpin && isSpinEnd}
                    weapon_name={w.weapon_name}
                    skin_name={w.skin_name}
                    rarity={w.rarity}
                    steam_image={w.steam_image}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {CajaAll.precio && (
        <button className={cl.button} disabled={isSpin} onClick={play}>
          Tirar {CajaAll.precio}€
        </button>
      )}
      {isSpinEnd && (
        <>
          <div className="background-modal"></div>
          <div className="container-resultado-caja">
            <h1 className="titulo-ruleta">GANADO</h1>
            {selectedWeapon && (
              <div className="content-box">
                <img src={selectedWeapon.steam_image} alt="" />
                <div>
                  <p>{selectedWeapon.weapon_name}</p>
                  {/* <p>Rareza: {selectedWeapon.rarity}</p> */}
                  <button className={cl.button} onClick={() => (window.location.href = `/ruleta/${caja}`)}>
                    Aceptar
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default McRoulette;
