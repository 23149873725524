import React from "react";
import cl from "./RouletteItem.module.scss";

const RouletteItem = ({ key, id, weapon_name, skin_name, rarity, steam_image, isLoser }) => {
  return (
    <div key={key} className={cl.evWeapon} style={isLoser ? { opacity: "0.5" } : { opacity: "1" }}>
      <div className={`${cl.evWeaponInner}`} id={String(id)}>
        <div className={`${cl.evWeaponRarity} ${cl[rarity]}`}></div>
        <img src={steam_image} alt={weapon_name} />
        <div className={cl.evWeaponText}>
          <p>{weapon_name}</p>
          <p>{skin_name}</p>
        </div>
      </div>
    </div>
  );
};

export default RouletteItem;
