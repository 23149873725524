import { useEffect, useRef } from "react";
import Contactanos from "./Contactanos";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";

import "./styles/Home.css";
export default function Home({ scroll, setScroll, setNavBarMomento }) {
  useEffect(() => {
    setNavBarMomento("INICIO");
  }, []);

  return (
    <div className="home-container">
      <Header />
      <Contactanos />
      <div className="container-btn-home">
        <a href="https://cfx.re/join/944v8y">JUGAR!!!</a>
        <a href="https://discord.gg/KYKPrVY44R">DISCORD</a>
      </div>
    </div>
  );
}
