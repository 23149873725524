import React, { useEffect, useState } from "react";
import CARRITO_WEB from "./productosImg/CARRITO_WEB.png";
import { ADDONSComp } from "./contenidos/addons/ADDONS";
import Footer from "./Footer";
import { agregarCarrito } from "./funcion";

export default function ADDONS({ setNavBarMomento }) {
  useEffect(() => {
    setNavBarMomento("ADDONS");
  }, []);
  return (
    <>
      <div className="container">
        <h1>ADDONS</h1>
        <div className="container-elements addons">
          {ADDONSComp.map((producto) => (
            <div className="producto addons" key={producto.id}>
              <img src={producto.img} alt="Coins Lazona Roleplay" />
              <div className="titulo-cantidad">
                <h3>{producto.titulo}</h3>
                <p>SLOTS: {producto.cantidad}</p>
              </div>
              <p>{producto.precio}€</p>
              <button title="Agregar al Carrito" className="Agregar-Carrito" onClick={() => agregarCarrito(producto, "ADDONS")}>
                + <i className="fa-solid fa-cart-shopping"></i>
              </button>
              <button
                className="newbutton addons-button"
                onClick={() => (window.location.href = `/form-compra/ADDONS/${producto.titulo}/${producto.precio}`)}
              >
                <i className="fa-solid fa-cart-shopping"></i>
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
