import "react-notifications/lib/notifications.css";
import { NotificationContainer, NotificationManager } from "react-notifications";

export const agregarCarrito = (producto, tipo) => {
  // Obtener el contenido actual del carrito de localStorage
  let carritoActual = JSON.parse(localStorage.getItem("CarritoCompras")) || [];

  // Verificar si el producto ya está presente en el carrito
  const productoExistenteIndex = carritoActual.findIndex((item) => item.titulo === producto.titulo);

  if (productoExistenteIndex !== -1) {
    // Si el producto existe, aumentar la cantidad en 1
    carritoActual[productoExistenteIndex].cantidad += 1;
    localStorage.setItem("CarritoCompras", JSON.stringify(carritoActual));
    NotificationManager.success("¡Producto Agregado al Carrito!", "¡Excelente!");
  } else {
    // Agregar el nuevo producto al carrito con el tipo especificado
    carritoActual.push({ ...producto, cantidad: 1, tipo: tipo });
    localStorage.setItem("CarritoCompras", JSON.stringify(carritoActual));
    NotificationManager.success("¡Producto Agregado al Carrito!", "¡Excelente!");
  }

  console.log(JSON.parse(localStorage.getItem("CarritoCompras")));
};
