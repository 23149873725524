// img caja
import caja_random from "../../productosImg/caja_random.png";
import caja_armass from "../../productosImg/caja_armass.png";
import caja_coins from "../../productosImg/caja_coins.png";
import producto1 from "../../productosImg/Ropa/ropa2.png";
import producto7 from "../../productosImg/organizaciones-img.png";
// imgProducto
import AP from "../../productosImg/Vip/AP.png";
import TEC9 from "../../productosImg/Vip/TEC9.png";
import HK from "../../productosImg/Vip/HK.png";
import M42 from "../../productosImg/Vip/M42.png";
import AKM from "../../productosImg/Vip/AKM.png";
import RECORTADA from "../../productosImg/Vip/RECORTADA.png";
import GLOCK19 from "../../productosImg/Vip/GLOCK19.png";
import producto4 from "../../productosImg/productos1.webp";

// probabilidad
// Covert: 1%
// Restricted: 4%
// Classified: 15%
// Rare: 80%
export const RuletaElements = [
  {
    caja: "1",
    precio: 5,
    titulo: "Caja de Coins",
    img: caja_coins,
    contenido: [
      {
        id: 1,
        steam_image: producto4,
        weapon_name: "1 COIN",
        cantidad: 1,
        precio: 1,
        rarity: "Classified",
        probabilidad: 5,
      },
      {
        id: 2,
        steam_image: producto4,
        weapon_name: "5 COINS",
        cantidad: 5,
        precio: 5,
        rarity: "Classified",
        probabilidad: 45,
      },
      {
        id: 3,
        steam_image: producto4,
        weapon_name: "10 COINS",
        cantidad: 10,
        precio: 10,
        rarity: "Classified",
        probabilidad: 20,
      },
      {
        id: 4,
        steam_image: producto4,
        weapon_name: "20 COINS",
        cantidad: 20,
        precio: 20,
        rarity: "Classified",
        probabilidad: 15,
      },
      {
        id: 5,
        steam_image: producto4,
        weapon_name: "30 COINS",
        cantidad: 30,
        precio: 30,
        rarity: "Classified",
        probabilidad: 9,
      },
      {
        id: 6,
        steam_image: producto4,
        weapon_name: "40 COINS",
        cantidad: 40,
        precio: 40,
        rarity: "Classified",
        probabilidad: 5,
      },
      {
        id: 7,
        steam_image: producto4,
        weapon_name: "50 COINS",
        cantidad: 50,
        precio: 50,
        rarity: "Classified",
        probabilidad: 1,
      },
    ],
  },
  {
    caja: "2",
    precio: 45,
    titulo: "Caja de Armas",
    img: caja_armass,
    contenido: [
      {
        id: 1,
        steam_image: AP,
        weapon_name: "AP",
        cantidad: 1,
        precio: 15,
        rarity: "Classified",
        probabilidad: 40,
      },
      {
        id: 2,
        steam_image: TEC9,
        weapon_name: "TEC9",
        cantidad: 2,
        precio: 25,
        rarity: "Classified",
        probabilidad: 40,
      },
      {
        id: 3,
        steam_image: HK,
        weapon_name: "HK",
        cantidad: 3,
        precio: 35,
        rarity: "Classified",
        probabilidad: 5,
      },
      {
        id: 4,
        steam_image: M42,
        weapon_name: "M4 BLOCK 2",
        cantidad: 5,
        precio: 50,
        rarity: "Classified",
        probabilidad: 5,
      },
      {
        id: 5,
        steam_image: AKM,
        weapon_name: "AKM",
        cantidad: 1,
        precio: 5,
        rarity: "Classified",
        probabilidad: 5,
      },
      {
        id: 6,
        steam_image: RECORTADA,
        weapon_name: "RECORTADA",
        cantidad: 1,
        precio: 5,
        rarity: "Classified",
        probabilidad: 2,
      },
      {
        id: 7,
        steam_image: GLOCK19,
        weapon_name: "GLOCK 19",
        cantidad: 1,
        precio: 5,
        rarity: "Classified",
        probabilidad: 3,
      },
    ],
  },
  {
    caja: "3",
    precio: 25,
    titulo: "Caja Random",
    img: caja_random,
    contenido: [
      {
        id: 1,
        steam_image: producto4,
        weapon_name: "1 COINS",
        cantidad: 1,
        precio: 1,
        rarity: "classified",
        probabilidad: 10,
      },
      {
        id: 2,
        steam_image: producto4,
        weapon_name: "20 COINS",
        cantidad: 2,
        precio: 20,
        rarity: "classified",
        probabilidad: 10,
      },
      {
        id: 3,
        steam_image: RECORTADA,
        weapon_name: "RECORTADA",
        cantidad: 3,
        precio: 90,
        rarity: "classified",
        probabilidad: 10,
      },
      {
        id: 4,
        steam_image: producto1,
        weapon_name: "1 PRENDA",
        cantidad: 5,
        precio: 15,
        rarity: "classified",
        probabilidad: 10,
      },
      {
        id: 5,
        steam_image: producto1,
        weapon_name: "CONJUNTO",
        cantidad: 1,
        precio: 50,
        rarity: "classified",
        probabilidad: 10,
      },
      {
        id: 6,
        steam_image: producto7,
        weapon_name: "LAVADO AL 80%",
        cantidad: 1,
        precio: 25,
        rarity: "classified",
        probabilidad: 10,
      },
      {
        id: 7,
        steam_image: GLOCK19,
        weapon_name: "GLOCK 19",
        cantidad: 1,
        precio: 70,
        rarity: "classified",
        probabilidad: 10,
      },
      {
        id: 8,
        steam_image: producto7,
        weapon_name: "SLOT EXTRA",
        cantidad: 1,
        precio: 10,
        rarity: "classified",
        probabilidad: 10,
      },
      {
        id: 9,
        steam_image: producto7,
        weapon_name: "PAQUITO EN SEDE",
        cantidad: 1,
        precio: 50,
        rarity: "classified",
        probabilidad: 10,
      },
      {
        id: 10,
        steam_image: producto7,
        weapon_name: "REAPARECER EN SEDE",
        cantidad: 1,
        precio: 20,
        rarity: "classified",
        probabilidad: 10,
      },
    ],
  },
];
