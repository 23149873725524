import React, { useEffect, useState } from "react";
import axios from "axios";
import { URL } from "../config";

export default function RecuperarContraseña({ setNavBarMomento }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [email, setEmail] = useState("");
  useEffect(() => {
    setNavBarMomento("");
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMessage("");
      setSuccessMessage("");
    }, 2000);
    return () => clearTimeout(timer);
  }, [errorMessage, successMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tokenPass = localStorage.getItem("tokenPass");
    if (tokenPass !== null && tokenPass !== undefined) {
      return setErrorMessage("Ya se ha enviado un correo de recuperación anteriormente");
    } else {
      try {
        const response = await axios.post(`${URL}/recuperarPass`, { email: email });
        setSuccessMessage(response.message);
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else {
          localStorage.setItem("tokenPass", response.data.tokenPass);
          setSuccessMessage(response.data.message);
        }
      } catch (error) {
        console.log(error);
        console.error(error);
        setErrorMessage("Error Desde El Servidor");
        setSuccessMessage("");
      }
    }
  };

  return (
    <div className="container-form">
      <div className="container-login-register">
        <form onSubmit={handleSubmit} className="form-login-register">
          <h1>Recuperar Contraseña</h1>
          <div className="inputs">
            <div className="input">
              <i className="fa-solid fa-envelope icon-input"></i>
              <input
                type="Email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email de tu Cuenta"
                autoComplete="on"
                required
              />
            </div>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Muestra el mensaje de error si existe */}
          {successMessage && <p className="success-message">{successMessage}</p>} {/* Muestra el mensaje de éxito si existe */}
          <div className="btn-form">
            <button type="submit"> Enviar</button>
          </div>
        </form>
      </div>
    </div>
  );
}
