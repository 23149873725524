import { useState } from "react";
import "./styles/Navbar.css";
import { Link } from "react-router-dom";
import ruleta from "./productosImg/ruleta.png";
import cash from "../img/cash.webp";

export default function Navbar({ activeMenuResponsive, setActiveMenuResponsive, scroll, navBarMomento, userLogin }) {
  // const [navBarMomento, setActive] = useState(true);
  const [dinero, setDinero] = useState(0);
  const handleCerrarSesion = () => {
    localStorage.removeItem("tokenlogin");
    window.location.href = "/";
  };
  const handleScrollToTop = () => {
    // Hacer scroll hacia arriba cuando se hace clic en el botón
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const RegisterButton = () => {
    setActiveMenuResponsive(false);
    window.location.href = "/register";
  };
  const LoginButton = () => {
    setActiveMenuResponsive(false);
    window.location.href = "/login";
  };
  return (
    <>
      <nav className={`navBar ${scroll <= -70 ? "scroll" : ""} ${activeMenuResponsive ? "active" : ""}`}>
        <div className="Navbar">
          <div className="logo">
            <Link to="/">La Zona RP</Link>
          </div>
          <ul className="list-navbar">
            <li>
              <Link
                onClick={() => setActiveMenuResponsive(false)}
                to="/"
                className={`${navBarMomento === "INICIO" ? "active" : ""}`}
              >
                INICIO
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setActiveMenuResponsive(false)}
                to="/coins"
                className={`${navBarMomento === "COINS" ? "active" : ""}`}
              >
                COINS
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setActiveMenuResponsive(false)}
                to="/organizaciones"
                className={`${navBarMomento === "ORG" ? "active" : ""}`}
              >
                ORGANIZACIONES
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setActiveMenuResponsive(false)}
                to="/addons"
                className={`${navBarMomento === "ADDONS" ? "active" : ""}`}
              >
                ADDONS
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setActiveMenuResponsive(false)}
                to="/ropa"
                className={`${navBarMomento === "ROPA" ? "active" : ""}`}
              >
                ROPA
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setActiveMenuResponsive(false)}
                to="/vip"
                className={`${navBarMomento === "VIP" ? "active" : ""}`}
              >
                VIP
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setActiveMenuResponsive(false)}
                to="/peds"
                className={`${navBarMomento === "PEDS" ? "active" : ""}`}
              >
                PEDS
              </Link>
            </li>

            {userLogin && (
              <li>
                <a
                  onClick={() => setActiveMenuResponsive(false)}
                  href="/perfil"
                  className={`${navBarMomento === "PERFIL" ? "active" : ""}`}
                >
                  PERFIL
                </a>
              </li>
            )}


          </ul>
          <div className="container-btns-navbar">
            <div>
              {!userLogin ? (
                <>
                  <button onClick={() => LoginButton()}> LOGIN</button>
                  <button onClick={() => RegisterButton()}>REGISTRO</button>
                </>
              ) : (
                <>
                  <div className="container-cash">
                    <Link onClick={() => setActiveMenuResponsive(false)} to="/recarga">
                      <img src={cash} alt="Ingresar dinero a la cartera img lazona Roleplay" />
                      <p>${userLogin.dinero}</p>
                    </Link>
                  </div>
                  <button onClick={handleCerrarSesion}>Cerrar Sesión</button>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
      <div className={`btn-subir ${scroll <= -259.71875 ? "scroll" : ""}`}>
        <button onClick={handleScrollToTop}>
          <i className="fa-solid fa-arrow-up"></i>
        </button>
      </div>
      <button
        onClick={() => setActiveMenuResponsive(!activeMenuResponsive)}
        className={`btn-bars-responsive ${scroll <= -70 ? "scroll" : ""}`}
      >
        <i className="fa-solid fa-bars"></i>
      </button>
    </>
  );
}
