import producto1 from "../../productosImg/Ropa/ropa.png";

export const Ropa = [
  {
    id: 1,
    img: producto1,
    cartel: "ROPA",
    titulo: "1 PRENDA",
    cantidad: 1,
    precio: 15,
  },
  {
    id: 2,
    img: producto1,
    cartel: "ROPA",
    titulo: "2 PRENDAS",
    cantidad: 2,
    precio: 25,
  },
  {
    id: 3,
    img: producto1,
    cartel: "ROPA",
    titulo: "3 PRENDAS",
    cantidad: 3,
    precio: 35,
  },
  {
    id: 4,
    img: producto1,
    cartel: "CONJUNTO",
    titulo: "5 PRENDAS",
    cantidad: 5,
    precio: 50,
  },
  {
    id: 5,
    img: producto1,
    cartel: "ACTUALIZAR",
    titulo: "ACTUALIZAR PRENDA",
    cantidad: 1,
    precio: 5,
  },
];
