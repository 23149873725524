import { useEffect, useRef, useState } from "react";
import "./App.css";
import Home from "./components/home/Home";
import { BrowserRouter, Link, Navigate, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import { changeUser } from "./peticiones/findUser";
import ResetPassword from "./components/ResetPassword";
import Register from "./components/Register";
import Navbar from "./components/home/Navbar";
import RecuperarContraseña from "./components/RecuperarContraseña";
import Coins from "./components/home/Coins";
import Organizaciones from "./components/home/Organizaciones";
import ADDONS from "./components/home/ADDONS";
import ROPA from "./components/home/ROPA";
import Vip from "./components/home/Vip";
import PEDS from "./components/home/PEDS";
import "./components/home/styles/Variables.css";
import ScrollToTop from "./ScrollToTop";
import "./components/styles/estilosGenerales.css";
import Ruleta from "./components/home/Ruleta";
import Footer from "./components/home/Footer";
import Cajas from "./components/home/Cajas";
import "./components/styles/Responsive.css";
import Contactar from "./components/home/Contactar";
import FormComprar from "./components/home/FormComprar";
import PagoExitoso from "./components/home/PagoExitoso";
import PagoFallido from "./components/home/PagoFallido";
import Recarga from "./components/Recarga";
import TerminosYCondiciones from "./components/TerminosYCondiciones";
import PoliticasdePrivacidad from "./components/PoliticasdePrivacidad";
import MiPerfil from "./components/MiPerfil";
import CarritoCompras from "./components/CarritoCompras";
import { NotificationContainer } from "react-notifications";
function App() {
  const [navBarMomento, setNavBarMomento] = useState("INICIO");
  const [userLogin, setUser] = useState();
  const [scroll, setScroll] = useState(0);
  const [activeMenuResponsive, setActiveMenuResponsive] = useState(false);
  // const [cantProductos, setcantProductos] = useState(0);
  const ref = useRef();

  useEffect(() => {
    changeUser(setUser);
  }, []);
  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("isVisible");
        observer.unobserve(entry.target);
      }
    });
  };

  useEffect(() => {
    const mains = document.querySelectorAll("main");
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "-15px",
      threshold: 0.4, // Change this threshold as needed
    });
    mains.forEach((section) => {
      observer.observe(section);
    });
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const div = ref.current;
      const { y } = div.getBoundingClientRect();
      const scroll = y;
      setScroll(scroll);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Navbar
          activeMenuResponsive={activeMenuResponsive}
          setActiveMenuResponsive={setActiveMenuResponsive}
          scroll={scroll}
          navBarMomento={navBarMomento}
          userLogin={userLogin}
        />
        <div ref={ref}>
          <Routes>
            <Route path="/" element={<Home setScroll={setScroll} setNavBarMomento={setNavBarMomento} />} />
            <Route path="/recuperar-contraseña" element={<RecuperarContraseña setNavBarMomento={setNavBarMomento} />} />
            <Route path="/contactar" element={<Contactar setNavBarMomento={setNavBarMomento} />} />
            <Route path="/coins" element={<Coins setNavBarMomento={setNavBarMomento} />} />
            <Route path="/organizaciones" element={<Organizaciones setNavBarMomento={setNavBarMomento} />} />
            <Route path="/addons" element={<ADDONS setNavBarMomento={setNavBarMomento} />} />
            <Route path="/ropa" element={<ROPA setNavBarMomento={setNavBarMomento} />} />
            <Route path="/vip" element={<Vip setNavBarMomento={setNavBarMomento} />} />
            <Route path="/peds" element={<PEDS setNavBarMomento={setNavBarMomento} />} />
            <Route path="/ruleta" element={<Cajas userLogin={userLogin} setNavBarMomento={setNavBarMomento} />} />
            <Route path="/ruleta/:caja" element={<Ruleta userLogin={userLogin} setNavBarMomento={setNavBarMomento} />} />
            <Route path="/pago-exitoso/:tipo" element={<PagoExitoso />} />
            <Route path="/pago-fallido" element={<PagoFallido />} />
            <Route path="/terminos-y-condiciones" element={<TerminosYCondiciones />} />
            <Route path="/PoliticasPrivacidad" element={<PoliticasdePrivacidad />} />
            <Route path="/perfil" element={<MiPerfil setNavBarMomento={setNavBarMomento} />} />
            {userLogin ? (
              <>
                <Route path="/login" element={<Navigate to="/" />} />
                <Route path="/recarga" element={<Recarga userLogin={userLogin} />} />
                <Route path="/register" element={<Navigate to="/" />} />
                <Route path="/resetpassword" element={<Navigate to="/" />} />
                <Route path="/form-compra/:tipo/:titulo/:precio" element={<FormComprar setNavBarMomento={setNavBarMomento} />} />
                <Route path="/carrito-compras" element={<CarritoCompras setNavBarMomento={setNavBarMomento} />} />
              </>
            ) : (
              <>
                <>
                  <Route path="/form-compra/:tipo/:titulo/:precio" element={<Login setNavBarMomento={setNavBarMomento} />} />
                  <Route path="/resetpassword" element={<ResetPassword setNavBarMomento={setNavBarMomento} />} />
                  <Route path="/register" element={<Register setNavBarMomento={setNavBarMomento} />} />
                  <Route path="/login" element={<Login setNavBarMomento={setNavBarMomento} />} />
                </>
              </>
            )}
          </Routes>
        </div>
        <Link className="carrito-compras" to="/carrito-compras">
          <i className="fa-solid fa-cart-shopping"></i>
          {/* {cantProductos > 0 && cantProductos} */}
        </Link>
      </BrowserRouter>
      <NotificationContainer />
      <Footer active={true} />
    </div>
  );
}

export default App;
