import React from "react";
import PoliticasPrivacidad from "../img/POLITICA_Y_PRIVACIDAD.pdf";
import TerminosCondiciones from "../img/TERMINOS_Y_CONDICIONES.pdf";
export default function Footer({ active }) {
  return (
    <main className={active ? "active" : ""}>
      <footer>
        <ul className="list-footer">
          <li>
            <h4>NOSOTROS</h4>
            <a href="https://discord.com/users/548513587090685974" target="_blank">
              !Tik0
            </a>

          </li>
          <li>
            <h4>POLITICA Y PRIVACIDAD</h4>
            <a href="/PoliticasPrivacidad">Leer</a>
          </li>
          <li>
            <h4>TERMINOS Y CONDICIONES</h4>
            <a href="/terminos-y-condiciones">Leer</a>
          </li>
          <li>
            <h4>
              <a href="https://discord.gg/KYKPrVY44R" target="_blank">
                LaZona RP ©
              </a>
            </h4>
          </li>
        </ul>
        <div className="redes">
          <a href="https://discord.gg/92MHN3gqBC" target= '_blank'>
          <i className="fa-solid fa-shield-halved"></i>
          </a>
          <a href="https://www.instagram.com/lazona_ontop/" target= '_blank'>
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a href="https://www.tiktok.com/@lazona.ontop" target= '_blank'>
            <i className="fa-brands fa-tiktok"></i>
          </a>
        </div>
      </footer>
    </main>
  );
}
