import { useEffect, useState } from "react";
import { changeUser, obtenerProdComprados } from "../peticiones/findUser";
import "./styles/miPerfil.css";
import { ADDONSComp } from "./home/contenidos/addons/ADDONS";
import { COINS } from "./home/contenidos/coins/COINS";
import { BANDAS } from "./home/contenidos/organizaciones/BANDAS";
import { CARTEL } from "./home/contenidos/organizaciones/CARTEL";
import { MAFIAS } from "./home/contenidos/organizaciones/MAFIAS";
import { Peds } from "./home/contenidos/peds/Peds";
import { Ropa } from "./home/contenidos/Ropa/Ropa";
import { RuletaElements } from "./home/contenidos/Ruleta/RuletaElements";
import { ARMAS_VIP_LVL_1 } from "./home/contenidos/vip/ARMAS_VIP_LVL_1";
import { ARMAS_VIP_LVL_2 } from "./home/contenidos/vip/ARMAS_VIP_LVL_2";
import { ARMAS_VIP_LVL_3 } from "./home/contenidos/vip/ARMAS_VIP_LVL_3";
const MiPerfil = ({ setNavBarMomento }) => {
  const [user, setUser] = useState(null);
  const [productos, setProductos] = useState();
  useEffect(() => {
    setNavBarMomento("PERFIL");
    if (localStorage.getItem("tokenlogin")) {
      changeUser(setUser);
    } else {
      window.location.href = "/login";
    }
  }, []);
  useEffect(() => {
    if (user) {
      obtenerProdComprados(user.id, setProductos);
    }
  }, [user]);
  const handleCambiarPassword = () => {
    window.location.href = "/recuperar-contraseña";
  };
  const formatearFecha = (fecha) => {
    const fechaCompra = new Date(fecha);

    // Obtener componentes de la fecha
    const dia = fechaCompra.getDate();
    const mes = fechaCompra.getMonth() + 1; // ¡Ojo! Meses en JavaScript van de 0 a 11
    const año = fechaCompra.getFullYear();

    // Formatear la fecha en el formato que desees
    const fechaFormateada = `${dia}/${mes}/${año}`;

    return fechaFormateada;
  };
  const TODOS_LOS_PRODUCTOS = [
    ...ADDONSComp,
    ...COINS,
    ...BANDAS,
    ...CARTEL,
    ...MAFIAS,
    ...Peds,
    ...Ropa,
    ...RuletaElements,
    ...ARMAS_VIP_LVL_1,
    ...ARMAS_VIP_LVL_2,
    ...ARMAS_VIP_LVL_3,
  ];

  // Función para obtener la imagen de un producto según su nombre_prod
  const obtenerImagenPorNombre = (nombre_prod) => {
    const productoEncontrado = TODOS_LOS_PRODUCTOS.find((producto) => producto.titulo === nombre_prod);

    if (productoEncontrado) {
      return productoEncontrado.img;
    }
    // Buscar en RuletaElements de manera diferente
    for (const caja of RuletaElements) {
      const contenidoEnCaja = caja.contenido.find((item) => item.weapon_name === nombre_prod);
      if (contenidoEnCaja) {
        return contenidoEnCaja.steam_image;
      }
    }
    // Devuelve una imagen por defecto si el producto no se encuentra
    return "123"; // Cambia esto a la imagen que prefieras
  };
  return (
    <div className="container">
      <h1>PERFIL DE USUARIO</h1>

      <main className="container-herramientas">
        <div className="container-perfil">
          <div className="content-perfil">
            {user && (
              <>
                <div className="union-perfil">
                  <p>
                    <strong>Nombre:</strong> <span>{user.username}</span>
                  </p>
                  <p>
                    <strong>Email:</strong> <span>{user.email}</span>
                  </p>
                </div>
                <div className="union-perfil">
                  <div className="btn-form">
                    <button onClick={handleCambiarPassword}>CambiarContraseña</button>
                  </div>
                </div>
                <div className="cosas-compradas">
                  <h1>Productos Adquiridos</h1>
                  <div className="productos-perfil">
                    <hr />
                    {productos &&
                      productos.map((product, index) => (
                        <>
                          <div key={index}>
                            <img src={obtenerImagenPorNombre(product.nombre_prod)} alt={product.nombre_prod} />
                            <p>
                              <strong>Tipo:</strong> {product.tipo_producto}
                            </p>
                            <p>
                              <strong>Nombre Producto: </strong> {product.nombre_prod}
                            </p>
                            <p>
                              <strong>Fecha Compra:</strong> {formatearFecha(product.fecha_compra)}
                            </p>
                            <p>
                              <strong>Cantidad:</strong> {product.cantidad}
                            </p>
                          </div>
                          <hr />
                        </>
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default MiPerfil;
