import producto1 from "../../productosImg/organizaciones-img.png";

export const MAFIAS = [
  // {
  //   id: 1,
  //   img: producto1,
  //   titulo: "MAFIA",
  //   cantidad: 20,
  //   precio: 100,
  // },
  // {
  //   id: 2,
  //   img: producto1,
  //   titulo: "SUPER MAFIA",
  //   cantidad: 25,
  //   precio: 150,
  // },
  // {
  //   id: 3,
  //   img: producto1,
  //   titulo: "SLOT EXTRA",
  //   cantidad: +1,
  //   precio: 10,
  // },
];
