import React, { useEffect, useState } from "react";
import { RuletaElements } from "./contenidos/Ruleta/RuletaElements.js";
import "swiper/css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import SliderRuleta from "./ruleta/SliderRuleta.jsx";
import McRoulette from "./ruleta/McRoulette.jsx";
import { useParams } from "react-router-dom";
export default function Ruleta({ userLogin, setNavBarMomento }) {
  const [contentCaja, setContentCaja] = useState([]);
  const [CajaAll, setCajaAll] = useState([]);
  const { caja } = useParams();

  const searchCaja = () => {
    // console.log(caja);
    const cajaParse = parseInt(caja);

    const cajaEncontrada = RuletaElements.find((caja) => caja.caja == cajaParse);
    if (cajaEncontrada) {
      setContentCaja(cajaEncontrada.contenido);
      setCajaAll(cajaEncontrada);
    } else {
      setContentCaja([]); // Puedes establecer un valor por defecto o hacer algo diferente
    }
  };
  useEffect(() => {
    setNavBarMomento("RULETA");
    searchCaja();
  }, []);
  const weaponsCount = 150;
  const transitionDuration = 0;
  return (
    <>
      <div id="ruleta-container" className="container">
        <SliderRuleta caja={caja} />
        <h1 className="titulo-ruleta">RULETA</h1>
        <McRoulette
          userLogin={userLogin}
          CajaAll={CajaAll}
          weapons={contentCaja}
          weaponsCount={weaponsCount}
          transitionDuration={transitionDuration}
        />
      </div>
    </>
  );
}
