import HK from "../../productosImg/Vip/HK.png";
import M42 from "../../productosImg/Vip/M42.png";
import AKM from "../../productosImg/Vip/AKM.png";
import M110 from "../../productosImg/Vip/m110.png";
import AKS from "../../productosImg/Vip/aks74u.png";
import AR15 from "../../productosImg/Vip/ar15.png";
import M4A1 from "../../productosImg/Vip/m4a1.png";
import RECORTADA from "../../productosImg/Vip/RECORTADA.png";
export const ARMAS_VIP_LVL_2 = [
  {
    id: 1,
    img: HK,
    cartel: "LVL 2",
    titulo: "HK-UMP",
    cantidad: 1,
    precio: 35,
  },
  {
    id: 2,
    img: M42,
    cartel: "LVL 2",
    titulo: "M4A1BLOCK2",
    cantidad: 1,
    precio: 45,
  },
  {
    id: 3,
    img: M42,
    cartel: "LVL 2",
    titulo: "M4 (NORMAL)",
    cantidad: 1,
    precio: 40,
  },
  {
    id: 4,
    img: AKM,
    cartel: "LVL 2",
    titulo: "AKM",
    cantidad: 1,
    precio: 50,
  },
  {
    id: 5,
    img: RECORTADA,
    cartel: "LVL 3",
    titulo: "RECORTADA",
    cantidad: 1,
    precio: 60,
  },
  {
    id: 6,
    img: AR15,
    cartel: "LVL 3",
    titulo: "AR 15",
    cantidad: 1,
    precio: 60,
  },
  {
    id: 7,
    img: M110,
    cartel: "LVL 3",
    titulo: "M110",
    cantidad: 1,
    precio: 80,
  },
  {
    id: 8,
    img: AKS,
    cartel: "LVL 3",
    titulo: "AKS 74-U",
    cantidad: 1,
    precio: 60,
  },

  //   {
  //     id: 4,
  //     img: TEC9,
  //     cartel: "LVL 2",
  //     titulo: "AKM",
  //     cantidad: 1,
  //     precio: 60,
  //   },
  //   {
  //     id: 5,
  //     img: AP,
  //     cartel: "LVL 2",
  //     titulo: "AP",
  //     cantidad: 1,
  //     precio: 40,
  //   },
  //   {
  //     id: 6,
  //     img: TEC9,
  //     cartel: "LVL 2",
  //     titulo: "TEC-9",
  //     cantidad: 1,
  //     precio: 40,
  //   },
  // {
  //   id: 3,
  //   img: producto1,
  //   cartel: "LVL 2",
  //   titulo: "SLOT EXTRA",
  //   cantidad: 1,
  //   precio: 10,
  // },
];
