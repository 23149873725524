import producto1 from "../../productosImg/organizaciones-img.png";

export const CARTEL = [
  // {
  //   id: 1,
  //   img: producto1,
  //   titulo: "CARTEL",
  //   cantidad: 30,
  //   precio: 120,
  // },
  // {
  //   id: 2,
  //   img: producto1,
  //   titulo: "SUPER CARTEL",
  //   cantidad: 40,
  //   precio: 160,
  // },
  // {
  //   id: 3,
  //   img: producto1,
  //   titulo: "SLOT EXTRA",
  //   cantidad: 1,
  //   precio: 10,
  // },
];
