import React from "react";
import "./styles/terminosycondiciones.css";
export default function TerminosYCondiciones() {
  return (
    <div className="contenedor-principal">
      <div className="Terminos-Y-condiciones-container">
        <h1>Términos y Condiciones de Uso - Lazona Roleplay</h1>
        <h4>
          <strong>
            Bienvenido a Lazona Roleplay. Al acceder y utilizar nuestros servicios, aceptas los siguientes términos y condiciones:
          </strong>
        </h4>
        <p>
          - Recordamos que las DONACIONES son actos totalmente voluntarios sin ningún animo de lucro con la finalidad de mantener
          el Servidor en pie, así como sus instalaciones y la optimización de este, es por ello por lo que NO se admite ningún
          tipo de DEVOLUCIÓN ya que después de todo es una donación, la cual se realiza sin la intención de obtener nada a cambio
          como se ha hecho toda la vida... pero en LaZona ofrecemos a nuestros jugadores pequeñas recompensas, variando según la
          cantidad donada con el fin de animarlos a contribuir con la causa ya que es muy complicado que el server se mantenga por
          si solo debido a la cantidad de gastos que este genera <br />- Cabe recalcar que la Administración del servidor se
          reserva el derecho a modificar los beneficios de los objetos en venta en cualquier momento y sin previo aviso, así como
          la cancelación y/o el retiro de la venta al publico de estos artículos sin dar explicación ninguna. Dicho derecho NO
          afecta a los artículos YA obtenidos previamente <br />
          <br />- <strong>NO se admite ningún tipo de reembolso. </strong> <br /> <br />- Si desea realizar cualquier cambio de
          algún articulo que haya adquirido, tendrá un plazo de 24horas desde la donación para realizar dicho cambio por un
          articulo del mismo valor o un valor superior abonando junto a este el dinero faltante <br />- Las donaciones vía PayPal
          deberán de realizarse como "Amigos & Familiares" en Euros (€), aportando el coste de Tarifa de dicha Transacción para
          así completar el costo del producto adquirido <br />- Las donaciones vía Paysafecard tendrán un costo de un 5€ extra del
          precio total del producto con la finalidad de cubrir los gastos de Transferencia que conlleva dicho método de pago Una
          vez REALIZADA la donación, estarás ACEPTANDO dichos Términos & Condiciones AUTOMÁTICAMENTE
        </p>
        <strong>Gracias por elegir Lazona Roleplay.</strong>
      </div>
    </div>
  );
}
