import React, { useState, useEffect } from "react";
import imgLeftHeader from "../img/imagenweb.webp";
import img1RightHeader from "../img/300.webp";
import img2RightHeader from "../img/2323.webp";
import img3RightHeader from "../img/34234.webp";
import img4RightHeader from "../img/aw3d.webp";
import img5RightHeader from "../img/asdw.webp";
import img6RightHeader from "../img/categoria3.webp";
import "./styles/header.css";

export default function Header() {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const imagePromises = [];
    const loadImage = (src) =>
      new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve();
      });

    const images = [img1RightHeader, img2RightHeader, img3RightHeader, img4RightHeader, img5RightHeader, img6RightHeader];
    images.forEach((imgSrc) => {
      imagePromises.push(loadImage(imgSrc));
    });

    Promise.all(imagePromises).then(() => {
      setImagesLoaded(true);
    });
  }, []);

  return (
    <header className="container-home">
      <div className="header-left">
        <h1>SOBRE NOSOTROS</h1>
        <p>
          En LaZona buscamos la igualdad para toda nuestra comunidad. Nuestro servidor se enfoca en proporcionar un espacio de
          juego sin las restricciones habituales, fomentando la diversión y la creatividad de nuestros usuarios. Creemos que un
          servidor con whitelist puede ofrecer una experiencia de rol de alta calidad, y nos esforzamos por mantener un ambiente
          inclusivo y acogedor para todos.
        </p>
        <div className="img-left-header">
          <img src={imgLeftHeader} alt="imagen header Lazona Roleplay" />
        </div>
      </div>
      {imagesLoaded ? (
        <div className="header-right">
          <img src={img1RightHeader} alt="imagen Header Lazona Roleplay" width="100%" />
          <img src={img2RightHeader} alt="imagen Header Lazona Roleplay" />
          <img src={img3RightHeader} alt="imagen Header Lazona Roleplay" />
          <img src={img4RightHeader} alt="imagen Header Lazona Roleplay" />
          <img src={img5RightHeader} alt="imagen Header Lazona Roleplay" />
          <img src={img6RightHeader} alt="imagen Header Lazona Roleplay" />
        </div>
      ) : (
        <p style={{ color: "white" }}>Cargando imágenes...</p>
      )}
    </header>
  );
}
