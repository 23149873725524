import axios from "axios";
import { URL } from "../config";

export const changeUser = async (setUser) => {
  try {
    const token = localStorage.getItem("tokenlogin");
    if (!token) {
      setUser(null);
      return;
    }
    const response = await axios.post(`${URL}/perfil`, { token: token });
    if (response.status === 200) {
      setUser(response.data.user);
    }
  } catch (error) {
    console.log(error);
  }
};
export const obtenerProdComprados = async (id, setProductos) => {
  try {
    const response = await axios.post(`${URL}/obtener-productos-comprados`, { id: id });
    if (response.status === 200) {
      setProductos(response.data.productos);
    }
  } catch (error) {
    console.error(error);
  }
};
export const restarRestarDinero = async (usuario, precio) => {
  try {
    await axios.post(`${URL}/restarRestarDinero`, { user: usuario, precio: precio });
  } catch (error) {
    console.error(error);
  }
};
export const guardarProductoWinner = async (cajaAll, winner, user) => {
  try {
    await axios.post(`${URL}/guardarProductoWinner`, { cajaAll: cajaAll, user: user, winner: winner });
  } catch (error) {
    console.error(error);
  }
};
