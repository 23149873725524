import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function PagoFallido({}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }, []);

  return (
    <main className="spinner-pagoexistoso">
      <h1>¡Error al realizar la acción!</h1>
      <h1>Volviendo a la Página Principal</h1>
      {isLoading && (
        <div className="spinner-border spinner" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </main>
  );
}
