import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

export default function PagoExitoso({}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { tipo } = useParams();
  useEffect(() => {
    if (tipo === "carrito") {
      localStorage.removeItem("CarritoCompras");
    }
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }, []);

  return (
    <main className="spinner-pagoexistoso">
      <h1>¡La Acción se Realizo con Éxito!</h1>
      <h1>Volviendo a la Página Principal</h1>
      {isLoading && (
        <div className="spinner-border spinner" role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
      )}
    </main>
  );
}
