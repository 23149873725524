import producto1 from "../../productosImg/productos1.webp";

export const COINS = [
  {
    id: 1,
    img: producto1,
    titulo: "COINS",
    cantidad: 5,
    precio: 10,
  },
  {
    id: 2,
    img: producto1,
    titulo: "COINS",
    cantidad: 10,
    precio: 20,
  },
  {
    id: 3,
    img: producto1,
    titulo: "COINS",
    cantidad: 20,
    precio: 35,
  },
  {
    id: 4,
    img: producto1,
    titulo: "COINS",
    cantidad: 30,
    precio: 50,
  },
  {
    id: 5,
    img: producto1,
    titulo: "COINS",
    cantidad: 40,
    precio: 55,
  },
  {
    id: 6,
    img: producto1,
    titulo: "COINS",
    cantidad: 50,
    precio: 60,
  },
  {
    id: 7,
    img: producto1,
    titulo: "COINS",
    cantidad: 100,
    precio: 100,
  },
  {
    id: 8,
    img: producto1,
    titulo: "COINS",
    cantidad: 200,
    precio: 190,
  },
  {
    id: 9,
    img: producto1,
    titulo: "COINS",
    cantidad: 300,
    precio: 250,
  },
];
