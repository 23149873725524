import HK from "../../productosImg/Vip/HK.png";
import M42 from "../../productosImg/Vip/M42.png";
import AKM from "../../productosImg/Vip/AKM.png";

export const ARMAS_VIP_LVL_3 = [
//   {
//     id: 1,
//     img: M42,
//     cartel: "LVL 2",
//     titulo: "HK-UMP",
//     cantidad: 1,
//     precio: 60,
//   },
//   {
//     id: 2,
//     img: AKM,
//     cartel: "LVL 2",
//     titulo: "M4A1BLOCK2",
//     cantidad: 1,
//     precio: 60,
//   },
//   {
//     id: 3,
//     img: HK,
//     cartel: "LVL 2",
//     titulo: "M4 (NORMAL)",
//     cantidad: 1,
//     precio: 60,
//   },
//   {
//     id: 4,
//     img: HK,
//     cartel: "LVL 2",
//     titulo: "HK-UMP",
//     cantidad: 1,
//     precio: 60,
//   },
//   {
//     id: 5,
//     img: M42,
//     cartel: "LVL 2",
//     titulo: "M4A1BLOCK2",
//     cantidad: 1,
//     precio: 60,
//   },
//   {
//     id: 6,
//     img: AKM,
//     cartel: "LVL 2",
//     titulo: "M4 (NORMAL)",
//     cantidad: 1,
//     precio: 60,
//   },
  //   {
  //     id: 4,
  //     img: TEC9,
  //     cartel: "LVL 2",
  //     titulo: "AKM",
  //     cantidad: 1,
  //     precio: 60,
  //   },
  //   {
  //     id: 5,
  //     img: AP,
  //     cartel: "LVL 2",
  //     titulo: "AP",
  //     cantidad: 1,
  //     precio: 40,
  //   },
  //   {
  //     id: 6,
  //     img: TEC9,
  //     cartel: "LVL 2",
  //     titulo: "TEC-9",
  //     cantidad: 1,
  //     precio: 40,
  //   },
  // {
  //   id: 3,
  //   img: producto1,
  //   cartel: "LVL 2",
  //   titulo: "SLOT EXTRA",
  //   cantidad: 1,
  //   precio: 10,
  // },
];
