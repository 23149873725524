import React, { useEffect, useState } from "react";
import "./styles/carritoCompras.css";
export default function CarritoCompras({ setNavBarMomento }) {
  const [productos, setProductos] = useState();
  const [precioTotal, setPrecioTotal] = useState(0);
  useEffect(() => {
    if (productos && productos.length > 0) {
      let total = 0;
      for (let i = 0; i < productos.length; i++) {
        total += parseFloat(productos[i].precio * productos[i].cantidad);
      }
      setPrecioTotal(total);
    }
  }, [productos]);

  const cargarProductos = () => {
    setProductos(JSON.parse(localStorage.getItem("CarritoCompras")));
  };
  useEffect(() => {
    setNavBarMomento("CarCompras");
    cargarProductos();
  }, []);
  const DeleteLocalStorage = () => {
    localStorage.removeItem("CarritoCompras");
    cargarProductos();
  };
  const eliminarProducto = (index) => {
    const nuevosProductos = [...productos];
    nuevosProductos.splice(index, 1);
    localStorage.setItem("CarritoCompras", JSON.stringify(nuevosProductos));
    cargarProductos();
  };
  return (
    <div className="container">
      <h1>CARRITO DE COMPRAS</h1>
      <div className="productos-carrito">
        {productos &&
          productos.map((producto, index) => (
            <div key={index}>
              <h1>{producto.titulo}</h1>
              <img src={producto.img} alt={producto.titulo} />
              <h4>{producto.precio}€</h4>
              <p style={{ color: "white" }}>cantidad: {producto.cantidad}</p>
              <div className="btns-carrito">
                <button onClick={() => eliminarProducto(index)}>
                  <i className="fa-solid fa-trash"></i>
                </button>
              </div>
            </div>
          ))}
        {productos && productos.length > 0 ? (
          <div className="btns-carrito">
            <button onClick={DeleteLocalStorage}>Vaciar Carrito</button>
            <p style={{ color: "white", wordSpacing: "2px", letterSpacing: "2px" }}>Total: {precioTotal}€</p>
            {/* <button onClick={handleComprar}>Comprar</button> */}
            <button onClick={() => (window.location.href = `/form-compra/Organizaciones/carrito/${precioTotal}`)}>Comprar</button>
          </div>
        ) : (
          <h1 style={{ textAlign: "center", margin: "auto", color: "white" }}>Carrito Vacio</h1>
        )}
      </div>
    </div>
  );
}
