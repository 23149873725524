import React from "react";
import "./styles/terminosycondiciones.css";
export default function PoliticasdePrivacidad() {
  return (
    <div className="contenedor-principal">
      <div className="Terminos-Y-condiciones-container">
        <h1>Politica Y Privacidad - Lazona Roleplay</h1>
        <p>
          <strong> POLÍTICA DE ENTREGA</strong> <br />
          <br />
          Entregamos todas las compras en un lapso de 1-72 horas dependiendo de la compra, con excepción de problemas técnicos o
          de mantenimiento en las plataformas de pago que puedan ocasionar un retraso en la entrega. Si no recibe su compra en el
          lapso de tiempo anteriormente indicado, contáctenos utilizando nuestro sistema de soporte en discord para ofrecerle
          ayuda en la entrega. <br />
          <br /> <strong> POLÍTICA DE DEVOLUCIONES - ARTÍCULOS VIRTUALES</strong> <br /> <br /> Ningún artículo virtual comprado
          podrá ser devuelto. En caso de que un producto comprado con créditos (Coins) tenga algún bug debe ponerse en contacto
          mediante nuestro sistema de soporte. <br />
          <br /> <strong> POLÍTICA DE CANCELACIÓN</strong> <br /> <br /> El usuario no podrá cancelar las donaciones, ninguna
          donación tendrá reembolso. En el momento que el dicho usuario trámite una donación, acepta lo anteriormente dicho.
          <br />
          <br /> <strong> POLÍTICA DE REEMBOLSO </strong> <br /> <br />
          No se ofrecen reembolsos de las donaciones hechas al servidor. <br />
          <br /> <strong>POLÍTICA DE REGALOS</strong> <br /> <br /> Al momento de recibir créditos (Coins) como regalo por parte
          de otro usuario: El responsable de dichos créditos será el usuario que regaló los mismos. En caso de obtener créditos
          (Coins) de manera fraudulenta la cuenta y el usuario serán retirados permanentemente de nuestros servicios
        </p>
        <strong>Gracias por elegir Lazona Roleplay.</strong>
      </div>
    </div>
  );
}
