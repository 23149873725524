import React from "react";
import "./styles/contactos.css";
export default function Contactanos() {
  return (
    <div>
      <div className="Contactos">
        <h1>CONTACTANOS</h1>
        <p>Escribe tu correo, nos pondremos en contacto contigo</p>
        <a href="/contactar">
          <button>CONTACTAR</button>
        </a>
      </div>
    </div>
  );
}
