import Ped1 from "../../productosImg/Peds/peds.png";
import Ped2 from "../../productosImg/Peds/peds2.png";
import Ped3 from "../../productosImg/Peds/peds3.png";
import pedscustom from "../../productosImg/Peds/pedscustom.png";


export const Peds = [
  {
    id: 1,
    img: Ped1,
    cartel: "PED",
    titulo: "1 PEDS",
    cantidad: 3,
    precio: 40,
  },
  {
    id: 2,
    img: Ped2,
    cartel: "PED",
    titulo: "2 PEDS",
    cantidad: 2,
    precio: 25,
  },
  {
    id: 3,
    img: Ped3,
    cartel: "PED",
    titulo: "3 PEDS",
    cantidad: 3,
    precio: 35,
  },
  {
    id: 4,
    img: pedscustom,
    cartel: "CUSTOM",
    titulo: "CUSTOM",
    cantidad: 1,
    precio: 50,
  },
];
