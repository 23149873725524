import React, { useEffect } from "react";
import { BANDAS } from "./contenidos/organizaciones/BANDAS";
import { MAFIAS } from "./contenidos/organizaciones/MAFIAS";
import { CARTEL } from "./contenidos/organizaciones/CARTEL";
import Footer from "./Footer";
import CARRITO_WEB from "./productosImg/CARRITO_WEB.png";
import { agregarCarrito } from "./funcion";

export default function Organizaciones({ setNavBarMomento }) {
  useEffect(() => {
    setNavBarMomento("ORG");
  }, []);
  return (
    <>
      <div className="container">
        <h1>BANDAS</h1>
        <div className="container-elements">
          {BANDAS.map((producto) => (
            <div className="producto carrousel peds" key={producto.id}>
              <img src={producto.img} className="img-ruletas" alt="Coins Lazona Roleplay" />
              <div className="titulo-cantidad">
                <h3>{producto.titulo}</h3>
                <p>SLOTS: {producto.cantidad}</p>
              </div>
              <p>{producto.precio}€</p>
              <button title="Agregar al Carrito" className="Agregar-Carrito" onClick={() => agregarCarrito(producto, "BANDAS")}>
               <i className="fa-solid fa-cart-shopping"></i>
              </button>
              <button
                className="newbutton"
                onClick={() => (window.location.href = `/form-compra/Organizaciones/${producto.titulo}/${producto.precio}`)}
              >
                PAGAR YA!! <i className="fa-solid fa-money-check"></i>
              </button>
            </div>
          ))}
        </div>
        <div className="container-elements">
          {MAFIAS.map((producto) => (
            <div className="producto carrousel peds" key={producto.id}>
              <img src={producto.img} className="img-ruletas" alt="Coins Lazona Roleplay" />
              <div className="titulo-cantidad">
                <h3>{producto.titulo}</h3>
                <p>SLOTS: {producto.cantidad}</p>
              </div>
              <p>{producto.precio}€</p>
              <button title="Agregar al Carrito" className="Agregar-Carrito" onClick={() => agregarCarrito(producto, "MAFIAS")}>
               <i className="fa-solid fa-cart-shopping"></i>
              </button>
              <button
                className="newbutton"
                onClick={() => (window.location.href = `/form-compra/Organizaciones/${producto.titulo}/${producto.precio}`)}
              >
                <i className="fa-solid fa-money-check"></i>
              </button>
            </div>
          ))}
        </div>
        <div className="container-elements">
          {CARTEL.map((producto) => (
            <div className="producto carrousel peds" key={producto.id}>
              <img src={producto.img} className="img-ruletas" alt="Coins Lazona Roleplay" />
              <div className="titulo-cantidad">
                <h3>{producto.titulo}</h3>
                <p>SLOTS: {producto.cantidad}</p>
              </div>
              <p>{producto.precio}€</p>
              <button title="Agregar al Carrito" className="Agregar-Carrito" onClick={() => agregarCarrito(producto, "CARTEL")}>
                 <i className="fa-solid fa-cart-shopping"></i>
              </button>
              <button
                className="newbutton"
                onClick={() => (window.location.href = `/form-compra/Organizaciones/${producto.titulo}/${producto.precio}`)}
              >
                <i className="fa-solid fa-money-check"></i>
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
