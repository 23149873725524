import React, { useEffect, useState } from "react";
import CARRITO_WEB from "./productosImg/CARRITO_WEB.png";
import { ARMAS_VIP_LVL_1 } from "./contenidos/vip/ARMAS_VIP_LVL_1";
import { ARMAS_VIP_LVL_2 } from "./contenidos/vip/ARMAS_VIP_LVL_2";
import { ARMAS_VIP_LVL_3 } from "./contenidos/vip/ARMAS_VIP_LVL_3";
import Footer from "./Footer";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import { agregarCarrito } from "./funcion";

export default function Vip({ setNavBarMomento }) {
  useEffect(() => {
    setNavBarMomento("VIP");
  }, []);

  return (
    <>
      <div className="container">
        <h1>ARMAS VIP</h1>
        <div className="container-elements carrousel">
          <Swiper
            slidesPerView={1}
            autoplay={{
              delay: 1500,
              disableOnInteraction: true,
            }}
            modules={[Autoplay]}
            spaceBetween={0}
            // navigation
            breakpoints={{
              450: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              576: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
            }}
            // navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            loop={true}
          >
            {ARMAS_VIP_LVL_1.map((producto) => (
              <SwiperSlide key={producto.id}>
                <div className="producto carrousel">
                  <p className="cartel-product">{producto.cartel}</p>
                  <img src={producto.img} alt="Coins Lazona Roleplay" />
                  <div className="titulo-cantidad carrousel">
                    <h3>{producto.titulo}</h3>
                    <p>SLOTS: {producto.cantidad}</p>
                  </div>
                  <p>{producto.precio}€</p>
                  <button
                    title="Agregar al Carrito"
                    className="Agregar-Carrito"
                    onClick={() => agregarCarrito(producto, "ARMAS VIP LVL 1")}
                  >
                  + <i className="fa-solid fa-cart-shopping"></i>
                  </button>
                  <button
                    className="newbutton"
                    onClick={() => (window.location.href = `/form-compra/ArmasVipLvl1/${producto.titulo}/${producto.precio}`)}
                  >
                PAGAR YA!! <i className="fa-solid fa-money-check"></i>
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="container-elements carrousel">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            // navigation
            autoplay={{
              delay: 1700,
              disableOnInteraction: true,
            }}
            modules={[Autoplay]}
            breakpoints={{
              450: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              576: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
            }}
            // navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            loop={true}
          >
            {ARMAS_VIP_LVL_2.map((producto) => (
              <SwiperSlide key={producto.id}>
                <div className="producto carrousel">
                  <p className="cartel-product">{producto.cartel}</p>
                  <img src={producto.img} alt="Coins Lazona Roleplay" />
                  <div className="titulo-cantidad carrousel">
                    <h3>{producto.titulo}</h3>
                    <p>SLOTS: {producto.cantidad}</p>
                  </div>
                  <p>{producto.precio}€</p>
                  <button
                    title="Agregar al Carrito"
                    className="Agregar-Carrito"
                    onClick={() => agregarCarrito(producto, "ARMAS VIP LVL 2")}
                  >
                    + <i className="fa-solid fa-cart-shopping"></i>
                  </button>
                  <button
                    className="newbutton"
                    onClick={() => (window.location.href = `/form-compra/ArmasVipLvl2/${producto.titulo}/${producto.precio}`)}
                  >
                    <i className="fa-solid fa-cart-shopping"></i>
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}
