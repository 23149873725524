import { Swiper, SwiperSlide } from "swiper/react";
import { RuletaElements } from "../contenidos/Ruleta/RuletaElements.js";
import "swiper/css";
// Import Swiper styles
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useEffect, useState } from "react";

export default function SliderRuleta({ caja }) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [rouletteWeapons, setRouletteWeapons] = useState([]);
  const searchCaja = () => {
    const cajaParse = parseInt(caja);

    const cajaEncontrada = RuletaElements.find((caja) => caja.caja == cajaParse);
    if (cajaEncontrada) {
      setRouletteWeapons(cajaEncontrada.contenido);
    } else {
      setRouletteWeapons([]); // Puedes establecer un valor por defecto o hacer algo diferente
    }
  };
  useEffect(() => {
    searchCaja();
  }, []);
  return (
    <Swiper
      autoplay={{
        delay: 1500,
        disableOnInteraction: true,
      }}
      slidesPerView={1}
      spaceBetween={0}
      modules={[Autoplay]}
      // navigation
      breakpoints={{
        450: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        576: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 0,
        },
      }}
      pagination={{
        clickable: true,
      }}
      loop={true}
      className="mySwiper"
    >
      {rouletteWeapons.map((producto, index) => (
        <SwiperSlide key={producto.id}>
          <div onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)}>
            <div className={`producto ruleta ${index === hoveredIndex ? "hovered" : ""}`}>
              <img src={producto.steam_image} alt="Coins Lazona Roleplay" />
              <h3 className={`${index === hoveredIndex ? "ver" : ""}`}>{producto.weapon_name}</h3>
              <div className="contenido-adicional">
                {/* <h3>{producto.titulo}</h3> */}
                <p>Probabilidad: {producto.probabilidad}%</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
