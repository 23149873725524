import { useEffect } from "react";
import { COINS } from "./contenidos/coins/COINS.js";
import CARRITO_WEB from "./productosImg/CARRITO_WEB.png";
import { agregarCarrito } from "./funcion.js";
export default function Coins({ setNavBarMomento }) {
  useEffect(() => {
    setNavBarMomento("COINS");
  }, []);

  return (
    <>
      <div className="container">
        <h1>COINS</h1>
        <div className="container-elements">
          {COINS.map((producto) => (
            <div className="producto carrousel peds" key={producto.id}>
              <img src={producto.img} className="img-ruletas" alt="Coins Lazona Roleplay" />
              <div className="titulo-cantidad">
                <h3>{producto.titulo}</h3>
                <p>CANTIDAD: {producto.cantidad}</p>
              </div>
              <p>{producto.precio}€</p>
              <button title="Agregar al Carrito" className="Agregar-Carrito" onClick={() => agregarCarrito(producto, "COINS")}>
                + <i className="fa-solid fa-cart-shopping"></i>
              </button>
              <button
                className="newbutton"
                onClick={() => (window.location.href = `/form-compra/Coins/${producto.titulo}/${producto.precio}`)}
              >
                <i className="fa-solid fa-cart-shopping"></i>
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
