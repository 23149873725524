import producto1 from "../../productosImg/productos1.webp";

export const ADDONSComp = [
  {
    id: 1,
    img: producto1,
    titulo: "NPC PROCESO",
    cantidad: 1,
    precio: 60,
  },
  {
    id: 2,
    img: producto1,
    titulo: "NPC VENTA",
    cantidad: 1,
    precio: 60,
  },
  {
    id: 3,
    img: producto1,
    titulo: "NPC RECOGIDA",
    cantidad: 1,
    precio: 60,
  },
  {
    id: 4,
    img: producto1,
    titulo: "LAVADO 80%",
    cantidad: 1,
    precio: 40,
  },
  {
    id: 5,
    img: producto1,
    titulo: "LAVADO 0%",
    cantidad: 1,
    precio: 60,
  },
  {
    id: 6,
    img: producto1,
    titulo: "HELICOPTERO EN SEDE",
    cantidad: 1,
    precio: 50,
  },
  {
    id: 7,
    img: producto1,
    titulo: "GPS COMPAÑEROS & ABATIDOS",
    cantidad: 1,
    precio: 40,
  },
  {
    id: 8,
    img: producto1,
    titulo: "PUNTO DE RADIOS",
    cantidad: 1,
    precio: 10,
  },
  {
    id: 9,
    img: producto1,
    titulo: "CANDADOS EN SEDE",
    cantidad: 1,
    precio: 10,
  },
  {
    id: 10,
    img: producto1,
    titulo: "PUNTO DE ACCESORIOS",
    cantidad: 1,
    precio: 20,
  },
  {
    id: 11,
    img: producto1,
    titulo: "50% EN LAS ARMAS",
    cantidad: 1,
    precio: 25,
  },
  {
    id: 12,
    img: producto1,
    titulo: "MESA DJ",
    cantidad: 1,
    precio: 10,
  },
  {
    id: 13,
    img: producto1,
    titulo: "PAQUITO EN SEDE",
    cantidad: 1,
    precio: 30,
  },
  {
    id: 14,
    img: producto1,
    titulo: "REAPARECER EN SEDE",
    cantidad: 1,
    precio: 20,
  },
  {
    id: 15,
    img: producto1,
    titulo: "+1 TRABAJO EXTRA",
    cantidad: 1,
    precio: 10,
  },
  {
    id: 16,
    img: producto1,
    titulo: "PUNTO DE ARMAS",
    cantidad: 1,
    precio: 40,
  },
  {
    id: 18,
    img: producto1,
    titulo: "FRECUENCIA DE RADIO (PRIVADA)",
    cantidad: 1,
    precio: 10,
  },
  {
    id: 19,
    img: producto1,
    titulo: "RESPAWN VIP",
    cantidad: 1,
    precio: 15,
  },
  {
    id: 20,
    img: producto1,
    titulo: "FUEGO AMIGO",
    cantidad: 1,
    precio: 30,
  },
  {
    id: 21,
    img: producto1,
    titulo: "PUNTO DE CHALECOS",
    cantidad: 1,
    precio: 10,
  },
];
