import producto1 from "../../productosImg/organizaciones-img.png";

export const BANDAS = [
  {
    id: 1,
    img: producto1,
    titulo: "BANDA",
    cantidad: 5,
    precio: 60,
  },
  {
    id: 2,
    img: producto1,
    titulo: "SUPER BANDA",
    cantidad: 20,
    precio: 120,
  },
  {
    id: 3,
    img: producto1,
    titulo: "SLOT EXTRA",
    cantidad: 1,
    precio: 10,
  },
];
