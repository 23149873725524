import React, { useState } from "react";
import "./styles/recarga.css";
import { handleComprar, handleOptionChange, handleRecargaPaypal } from "../peticiones/funcionesFromFactura";
import MpImg from "./img/tarjetas.webp";
import paypalImg from "./img/paypal.webp";
export default function Recarga({ userLogin }) {
  const [money, setMoney] = useState(100);
  const [selectedOption, setSelectedOption] = useState("opcion1");

  const handleRecarga = () => {
    handleRecargaPaypal(money, selectedOption, userLogin);
  };
  const handleKeyDown = (e) => {
    if (e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  return (
    <div className="container-recarga">
      <h1>RECARGA SALDO</h1>
      <div className="money-input">
        <input
          type="number"
          value={money}
          min={0}
          step="any"
          onChange={(e) => setMoney(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <span>EUR</span>
      </div>
      <div className="selector">
        <label>
          <input
            type="radio"
            name="opcion"
            value="opcion1"
            required
            checked={selectedOption === "opcion1"}
            onChange={() => handleOptionChange("opcion1", setSelectedOption)}
          />
          <img src={MpImg} alt="" />
        </label>
        <label>
          <input
            type="radio"
            name="opcion"
            value="opcion2"
            required
            checked={selectedOption === "opcion2"}
            onChange={() => handleOptionChange("opcion2", setSelectedOption)}
          />
          <img src={paypalImg} alt="" />
        </label>
        {/* <label>
                <input
                  type="radio"
                  name="opcion"
                  value="opcion3"
                  checked={selectedOption === "opcion3"}
                  onChange={() => handleOptionChange("opcion3")}
                />
                Opción 3
              </label> */}
      </div>
      <div className="btn-form" onClick={handleRecarga}>
        <button type="submit">Cargar</button>
      </div>
    </div>
  );
}
