import React, { useEffect, useState } from "react";
import CARRITO_WEB from "./productosImg/CARRITO_WEB.png";
import { RuletaElements } from "./contenidos/Ruleta/RuletaElements.js";
import Footer from "./Footer";
import caja_ruleta from "./productosImg/caja-ruleta.png";
import { agregarCarrito } from "./funcion.js";

export default function Cajas({ userLogin, setNavBarMomento }) {
  useEffect(() => {
    setNavBarMomento("RULETA");
  }, []);

  return (
    <>
      <div className="container">
        <h1>CAJAS</h1>
        <div className="container-elements addons">
          {RuletaElements.map((producto, index) => (
            <div className="producto cajas" key={index} onClick={() => (window.location.href = `/ruleta/${producto.caja}`)}>
              <img src={producto.img} alt="Coins Lazona Roleplay" />
              <div className="titulo-cantidad">
                <h3>{producto.titulo}</h3>
                {/* <p>SLOTS: {producto.cantidad}</p> */}
              </div>
              <p>{producto.precio}€</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
