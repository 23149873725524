import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import mostrarPass from "./img/mostrarPass.png";
import ocultarPass from "./img/ocultarPass.png";
import "./styles/Login-Register.css";
import { URL } from "../config";

export default function Login({ setNavBarMomento }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setNavBarMomento("");
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMessage("");
      setSuccessMessage("");
    }, 2000);
    return () => clearTimeout(timer);
  }, [errorMessage, successMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      email: email,
      password: password,
    };
    axios
      .post(`${URL}/login`, formData)
      .then((response) => {
        const tokenlogin = response.data.tokenLogin;
        localStorage.setItem("tokenlogin", tokenlogin);
        if (response.status === 220) {
          setErrorMessage("La Contraseña es Incorrecta");
        } else if (response.status === 200) {
          setIsLoading(true);
          setSuccessMessage("Inicio de sesión exitoso");
          window.location.href = "/";
        } else if (response.status === 229) {
          setErrorMessage("El Usuario con ese Email o Usuario no Existe!");
        }
      })
      .catch((error) => {
        // Manejar los errores de la solicitud (por ejemplo, mostrar un mensaje de error)
        console.error(error);
        setErrorMessage("Error Desde El Servidor");
        setSuccessMessage("");
        // window.location.href = "/#/Error404";
      });
  };
  return (
    <div className="container-form">
      <div className="container-login-register">
        <form onSubmit={handleSubmit} className="form-login-register">
          {isLoading && (
            <div className="spinner">
              <div className="spinner-border " role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <h1>Iniciar sesión</h1>
          <div className="inputs">
            <div className="input">
              <i className="fa-solid fa-envelope icon-input"></i>
              <input
                type="text"
                placeholder="Email o Usuario"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input">
              <i className="fa-solid fa-lock icon-input"></i>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <img
                className="icon-mostar-pass"
                onClick={() => setShowPassword(!showPassword)}
                src={`${showPassword ? mostrarPass : ocultarPass}`}
                alt="icono ocultar password login de paginas web coderplate40 "
              />
            </div>
          </div>
          <div className="texto-form">
            <p>
              <Link to="/recuperar-contraseña">¿Has olvidado tu contraseña?</Link>
            </p>
            <p>
              ¿No tienes cuenta? <Link to="/register">Regístrate</Link>
            </p>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Muestra el mensaje de error si existe */}
          {successMessage && <p className="success-message">{successMessage}</p>} {/* Muestra el mensaje de éxito si existe */}
          <div className="btn-form">
            <button type="submit"> Iniciar sesión</button>
          </div>
        </form>
      </div>
    </div>
  );
}
