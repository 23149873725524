import React, { useEffect, useState } from "react";
import CARRITO_WEB from "./productosImg/CARRITO_WEB.png";
import { Ropa } from "./contenidos/Ropa/Ropa";
import Footer from "./Footer";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import { agregarCarrito } from "./funcion";

export default function Vip({ setNavBarMomento }) {
  useEffect(() => {
    setNavBarMomento("ROPA");
  }, []);

  return (
    <>
      <div className="container">
        <h1>ROPA PERSONALIZADA</h1>
        <div className="container-elements carrousel">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            autoplay={{
              delay: 1500,
              disableOnInteraction: true,
            }}
            modules={[Autoplay]}
            // navigation
            breakpoints={{
              450: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              576: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
            }}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            loop={true}
          >
            {Ropa.map((producto) => (
              <SwiperSlide key={producto.id}>
                <div className="producto carrousel ropa">
                  <p className="cartel-product">{producto.cartel}</p>
                  <img src={producto.img} alt="Coins Lazona Roleplay" />
                  <div className="titulo-cantidad carrousel">
                    <h3>{producto.titulo}</h3>
                    <p>SLOTS: {producto.cantidad}</p>
                  </div>
                  <p>{producto.precio}€</p>
                  <button
                    title="Agregar al Carrito"
                    className="Agregar-Carrito"
                    onClick={() => agregarCarrito(producto, "ROPA PERSONALIZADA")}
                  >
                   <i className="fa-solid fa-cart-shopping"></i>
                  </button>
                  <button
                    className="newbutton"
                    onClick={() =>
                      (window.location.href = `/form-compra/ROPAPERSONALIZADA/${producto.titulo}/${producto.precio}`)
                    }
                  >
                PAGAR YA!! <i className="fa-solid fa-money-check"></i>
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}
