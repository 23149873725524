import axios from "axios";
import { URL } from "../config";
// verficia que la direccion sea correcta y la guarda
export const handleOptionChange = (option, setSelectedOption) => {
  setSelectedOption(option);
  console.log(option);
};
export const handleDireccionCorrecta = async (setIsLoading, data, setSuccessMessage, setErrorMessage) => {
  const direcciones = `${data.direccion}, ${data.direccion2} ${data.ciudad} ${data.pais} ${data.CodPostal}`;
  const formData = {
    nombre_apellido: data.nombre + " " + data.apellido,
    titulo: data.titulo,
    tipo: data.tipo,
    email: data.email,
    direccion: direcciones,
    telefono: data.telefono,
    total: parseFloat(data.precio),
    token: localStorage.getItem("tokenlogin"),
    metodo_pago: data.selectedOption === "opcion1" ? "Stripe" : "Paypal",
  };

  // if *(formData.metodo_pago === "Mercado_Pago") {
  //   formData.total = (Math.round(formData.total) * 1125) / 2;
  //   formData.total = Math.round(formData.total / 1000) * 1000;
  // }
  setIsLoading(true);
  try {
    const response = await axios.post(`${URL}/guardar-factura-pago`, formData);
    if (response.status === 200) {
      setSuccessMessage("Dirección Guardada Correctamente!");
      handleComprar(response.data.data, setIsLoading);
      setIsLoading(false);
    } else if (response.status === 500) {
      setErrorMessage(response.data.message);
    } else if (response.status === 203) {
      setErrorMessage(response.data.error);
    }
  } catch (error) {
    console.log(error);
    setErrorMessage("error!");
    setSuccessMessage("");
  }
};

// CREA UN SERVICIO PARA PAYPAL PAGAR
const handleComprar = async (formData, setIsLoading) => {
  setIsLoading(true);
  if (formData.metodo_pago === "Paypal") {
    if (formData.titulo_producto === "carrito") {
      const productos = JSON.parse(localStorage.getItem("CarritoCompras"));
      try {
        const response = await axios.post(`${URL}/create-order-paypal`, { formData: formData, productos: productos });
        // Redirigir a la página de PayPal
        window.location.href = response.data;
        setIsLoading(false);
      } catch (error) {
        console.error("Error al procesar el pago:", error);
        // window.location.href = "/Error404";
      }
    } else {
      try {
        const response = await axios.post(`${URL}/create-order-paypal`, { formData: formData });
        // Redirigir a la página de PayPal
        window.location.href = response.data;
        setIsLoading(false);
      } catch (error) {
        console.error("Error al procesar el pago:", error);
        // window.location.href = "/Error404";
      }
    }
  } else {
    if (formData.titulo_producto === "carrito") {
      const productos = JSON.parse(localStorage.getItem("CarritoCompras"));
      try {
        const response = await axios.post(`${URL}/create-order-stripe`, { formData: formData, productos: productos });
        setIsLoading(false);
        console.log();
        window.location.href = response.data;
      } catch (error) {
        console.error("Error al procesar el pago:", error);
        // window.location.href = "/Error404";
      }
    } else {
      try {
        const response = await axios.post(`${URL}/create-order-stripe`, { formData: formData });
        setIsLoading(false);
        window.location.href = response.data;
      } catch (error) {
        console.error("Error al procesar el pago:", error);
        // window.location.href = "/Error404";
      }
    }
  }
};
// CREA UN SERVICIO PARA PAYPAL PAGAR
export const handleRecargaPaypal = async (Monto, selectedOption, userLogin) => {
  console.log(selectedOption);
  if (selectedOption === "opcion2") {
    try {
      const email = userLogin.email;
      const response = await axios.post(`${URL}/create-Recarga-paypal`, { Monto: Monto, email: email });
      // Redirigir a la página de PayPal
      window.location.href = response.data;
    } catch (error) {
      console.error("Error al procesar el pago:", error);
      // window.location.href = "/Error404";
    }
  } else {
    try {
      const email = userLogin.email;
      const response = await axios.post(`${URL}/create-Recarga-stripe`, { Monto: Monto, email: email });
      // Redirigir a la página de PayPal
      window.location.href = response.data;
    } catch (error) {
      console.error("Error al procesar el pago:", error);
      // window.location.href = "/Error404";
    }
  }
};
